@import "utils/setup";
@import "blocks/shared/setup";

html {
  line-height: 1.5;
  font-size: 100%;
  color: $text-color;
  height: 100%;

  @include lg() {
    font-size: 16px;
  }
}

body {
  font-family: $font-primary;
  background-color: $body;
  color: $text-color;
}

.body-lock {
  overflow: hidden;
}

*,
:after,
:before {
  box-sizing: border-box;
}

button,
input {
  font-family: $font-primary;

  &:focus {
    outline: none;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.container,
.container-desktop {
  max-width: 1360px;
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.container-desktop {
  @include sm() {
    padding: 0;
  }
}

h2 {
  @include font(32, 41.6px, 700);

  @include sm() {
    @include font(30, 39px);
  }
}

h3 {
  @include font(24, 28px, 600);
}

h2,
h3 {
  color: $neutral-100;
}

.d-flex {
  display: flex;
}

.justify-content-b {
  justify-content: space-between;
}

.justify-content-c {
  justify-content: center;
}

.align-items-c {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.hidden-xs {
  @include xs {
    @include hidden();
  }
}

.hidden {
  @include hidden();
}

.hidden-desktop {
  @include hidden();

  @include sm() {
    display: block !important;
    visibility: visible !important;
  }
}

.hidden-desktop-md {
  @include hidden();

  @include md() {
    display: block !important;
    visibility: visible !important;
  }
}

.hidden-mobile-md {
  display: block !important;
  visibility: visible !important;

  @include md() {
    @include hidden();
  }
}

.hidden-md {
  @include md {
    @include hidden();
  }
}

.hidden-sm {
  @include sm() {
    @include hidden();
  }
}

.hidden-and-show-sm {
  @include hidden();

  @include sm() {
    display: flex !important;
    visibility: visible !important;
  }
}

.hidden-and-show-md {
  @include hidden();

  @include md() {
    display: flex !important;
    visibility: visible !important;
  }
}

.hidden-and-show-xs {
  @include hidden();

  @include xs {
    display: flex !important;
    visibility: visible !important;
  }
}

.hidden-lg {
  @include lg {
    @include hidden();
  }
}

.hidden-lg-1400 {
  @include lg-1400 {
    @include hidden();
  }
}

.hidden-desktop-and-show-lg-1400 {
  @include hidden();

  @include lg-1400 {
    display: flex !important;
    visibility: visible !important;
  }
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.m-0-auto {
  margin: 0 auto;
}

.mb-64 {
  margin-bottom: 64px;
}

.mb-24 {
  margin-bottom: 24px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.italic {
  font-family: "Open Sans";
  font-style: italic;
}

.o-hidden {
  overflow: hidden !important;
}

.image {
  margin-top: 24px;
  margin-bottom: 16px;

  img {
    display: block
  }

  figcaption {
    @include font(14, 16px, 400);
    margin-top: 12px;
    color: #D2D2D2;
    text-align: left;

    @include sm() {
      margin-top: 8px;
      @include font(12px, 16px);
    }
  }
}

main {
  padding-top: 68px;

  @include md {
    padding-top: 0;
  }
}

.adv {
  &__title {
    color: #ADADAD;
    @include font(12px, 16px, 400);
    display: block;
    margin-bottom: 8px;
  }

  .adsbygoogle {
    border: solid 1px #D2D2D2 40%;
  }
}