.header {
  height: 68px;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $white;
  border-bottom: solid 1px rgba(210, 210, 210, 0.4);

  @include md {
    height: 70px;
  }

  .container {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    @include md {
      img {
        @include size(138px, 46px);
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__supportBtn {
    margin-right: 24px;
    border: solid 1px $main;
    @include font(14px, 20px, 600);
    color: $text-color;
    height: 36px;
    padding: 0 16px;
    border-radius: 20px;
    transition: 0.2s ease;
    transition-property: background-color, color;
    display: flex;
    align-items: center;

    @include lg-1400 {
      display: none;
    }

    &:hover {
      background-color: $main;
      color: $white;
    }

    svg,
    img {
      margin-right: 10px;
    }
  }
}

.header-mobile-subnav {
  display: none;
  border-bottom: solid 1px rgba(210, 210, 210, 0.4);
  margin-top: 70px;

  @include md {
    overflow: auto;
    @include hide-scroll();
    display: block;

    >div {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      height: 50px;
    }

    a {
      @include font(14px, 18px, 600);
      width: fit-content;
      padding: 0 12px;
      border-right: solid 1px rgba(210, 210, 210, 0.4);

      &:last-child {
        border-right: none;
      }

      &:first-child {
        padding-left: 0;
      }
    }
  }
}

.nav {
  margin-left: 30px;
  margin-right: 13px;

  @include md {
    display: none;
  }

  &__item {
    color: $text-color;
    @include font(14px, 20px, 600);
    padding: 0 13px;
    border-right: solid 1px rgba(210, 210, 210, 0.4);

    @include lg {
      padding: 0 10px;
    }

    &:first-child {
      padding-left: 0;
    }
  }
}

.video-btn {
  background-color: $main;
  color: $white;
  border-radius: 20px;
  @include font(14px, 20px, 600);
  padding: 0 8px;
  height: 28px;
  display: flex;
  align-items: center;

  svg,
  img {
    margin-right: 4px;
  }
}

.languages-list {
  position: relative;
  margin-left: 28px;
  margin-right: 24px;

  @include lg-1400 {
    margin: 0 12px;
  }

  &__btn {
    background-color: transparent;
    padding: 0;
    text-transform: uppercase;
    border: none;
    @include font(14px, 20px, 600);
    display: flex;
    align-items: center;
    color: $text-color;
    background-color: $white;
    cursor: pointer;

    &:hover {
      .languages-list__dropdown {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__dropdown {
    background-color: $white;
    text-transform: uppercase;
    position: absolute;
    z-index: 11;
    top: 30px;
    padding: 4px 8px;
    width: 64px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.08)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.06));
    transform: translateX(-50%);
    left: 50%;
    top: -7px;
    border-radius: 0 0 4px 4px;
    border: solid 1px rgba(210, 210, 210, 0.5);
    border-radius: 4px;

    @include sm {
      width: 56px;
    }

    &_itm {
      color: $text-color;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 8px;
      padding-right: 22px;
      margin-bottom: 4px;
      border-radius: 4px;
      @include font(14px, 20px, 600);
      transition: 0.2s linear;
      transition-property: color, background-color;
      cursor: pointer;

      svg {
        min-width: 16px;
      }

      &:hover {
        background-color: $main;
        color: $white;
      }

      &:first-child {
        padding-right: 8px;

        &:hover {
          background-color: $white;
          color: $text-color;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.hamburger-menu {
  position: relative;
  background-color: transparent;
  cursor: pointer;
  border: none;
  @include size(28px, 28px);

  &_active {
    span {
      &:first-child {
        width: 21px;
        transform: rotate(45deg) translateY(4px);
      }

      &:last-child {
        width: 21px;
        transform: rotate(-45deg) translateY(-4px);
      }
    }

  }

  &__item {
    display: block;
    height: 2px;
    background-color: $text-color;
    transition: all 0.2s ease;
    position: relative;
    border-radius: 16px;

    &:first-child {
      width: 21px;
    }

    &:last-child {
      width: 13px;
      margin-top: 4px;
    }
  }
}

.header-popup {
  position: fixed;
  box-shadow: 0px 6px 6px -3px #00000033;
  top: 68px;
  left: 0;
  width: 100%;
  height: calc(100vh - 68px);
  z-index: 1001;
  background-color: $white;
  transform: translateX(100%);
  transition: 0.2s linear;
  opacity: 0;
  transition-property: transform, opacity;

  &_active {
    transform: translateX(0);
    opacity: 1;
  }

  >div {
    padding-top: 32px;
    height: 100%;
    overflow-y: auto;

    @include sm {
      padding-top: 24px;
    }
  }

  @include md {
    top: 70px;
    height: calc(100vh - 70px);
  }

  &__searches {
    margin-top: 16px;
    margin-bottom: 48px;

    @include sm {
      margin-bottom: 28px;
      margin-top: 24px;
    }
  }
}

.popular-searches {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 32px;

  &_variant-2 {
    grid-template-columns: repeat(4, 1fr);
    gap: 16px 48px;
    padding-bottom: 8px;

    @include sm {
      padding-bottom: 12px;
    }

    .popular-searches__title {
      grid-column: span 4;
    }
  }

  @include sm {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  &__title {
    @include font(16px, 21px, 700);

    @include md {
      margin-bottom: 8px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;

    @include sm {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &:nth-child(4),
      &:nth-child(3) {
        display: none;
      }
    }

    &-itm {
      color: $text-color;
      margin-bottom: 12px;
      opacity: 0.5;
      transition: opacity 0.2s linear;

      @include font(14px, 18.5px, 400);

      @include md {
        @include font(16px, 21px, 400);
      }

      &:hover {
        opacity: 1;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.header-categories {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 64px 32px;

  @include sm {
    grid-template-columns: 1fr;
    gap: 13px;
  }

  &__mainItem {
    padding-bottom: 16px;
    border-bottom: solid 2px rgba(210, 210, 210, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: border-color 0.3s ease-in-out;

    @include sm {
      border-width: 1px;
      padding-bottom: 12px;
    }

    &-title {
      color: $text-color;
      width: 100%;
      @include font(20px, 26.5px, 800);
      display: flex;
      align-items: center;

      >svg {
        margin-right: 4px;
      }

      @include sm {
        @include font(16px, 21px, 700);
      }
    }

    &-video {
      color: $main;
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }
    }

    &-btn {
      padding: 0;
      border: none;
      @include size(24px, 24px);
      @include center-flex();
      background-color: transparent;
      cursor: pointer;
      display: none;

      @include sm {
        display: block;
      }

      img {
        transition: transform 0.2s linear;
      }
    }
  }

  &__submenu {
    margin-top: 16px;
    display: flex;
    flex-direction: column;

    @include sm {
      max-height: 0px;
      margin-top: 0;
      transition: max-height 0.3s ease-in-out;
      overflow: hidden;
    }

    &_itm {
      margin-bottom: 12px;
      color: $text-color;
      @include font(16px, 22px, 500);

      @include sm {
        padding-left: 12px;
        border-bottom: solid 1px rgba(210, 210, 210, 0.4);
        padding-bottom: 8px;
        margin-bottom: 8px;
        @include font(14px, 21px, 500);

        &:first-child {
          padding-top: 4px;
        }
      }

      &:last-child {
        margin-bottom: 0;

        @include sm {
          margin-bottom: 11px;
        }
      }
    }
  }

  &__item {
    &_active {
      .header-categories__mainItem {
        border-bottom: transparent;
      }

      .header-categories__mainItem-btn img {
        transform: rotate(180deg);
      }

      .header-categories__submenu {
        max-height: 200px;
      }
    }

    &_mob-only {
      display: none;

      @include md {
        display: block;
      }
    }
  }
}

.header-socials {
  padding-top: 24px;
  border-top: solid 2px rgba(210, 210, 210, 0.4);
  margin-top: 64px;
  padding-bottom: 32px;

  @include sm {
    margin-top: 13px;
    border: none;
    padding-top: 0;
    padding-bottom: 100px;
  }

  &__title {
    color: $text-color;
    @include font(20px, 26.5px, 800);

    @include sm {
      @include font(16px, 21px, 700);
    }
  }

  &__items {
    display: flex;
    margin-top: 24px;

    @include md {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
    }

    @include sm {
      margin-top: 16px;
      grid-template-columns: 1fr 1fr;
      gap: 12px;
    }
  }

  a {
    display: flex;
    align-items: center;
    color: $text-color;
    @include font(16px, 22px, 500);
    margin-left: 16px;

    @include md {
      margin-left: 0;
    }

    svg,
    img {
      margin-right: 8px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}