.live-stream-bullet {
  @include font(14, 17px, 500);
  padding: 5px 10px 4px 10px;
  color: $white;
  border-radius: 4px;
  background-color: #FA4B4B;
  display: flex;
  align-items: center;
  width: fit-content;

  &__text {
    margin-left: 8px;
  }
}