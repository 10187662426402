.tabs {
  $root: &;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @include font(12px, 16px, 500);
  color: #D2D2D2;
  padding-bottom: 16px;
  min-width: 210px;

  &__item {
    padding: 2px 8px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    &_active {
      color: $main;
      background-color: #FEF4EF;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &_light {
    #{$root}__item {
      color: $white;

      &_active {
        background-color: rgba(255, 255, 255, 0.16);
      }
    }
  }
}