.search {
  height: 48px;
  min-height: 48px;
  border: solid 1.5px #DBDBDB;
  border-radius: 100px;
  position: relative;

  @include md {
    height: auto;
    min-height: fit-content;
    border-radius: 0;
    border: none;
  }

  &__input {
    padding-left: 16px;
    padding-right: 78px;
    @include font(16px, 24px, 500);
    color: $text-color;
    height: 100%;
    width: 100%;
    border: none;
    background-color: transparent;
    border-radius: inherit;

    &::placeholder {
      color: #ADADAD;
    }

    @include md {
      height: 48px;
      border-radius: 32px;
      border: solid 1.5px #DBDBDB;
      padding-left: 44px;
      font-weight: 400;
      padding-right: 16px;
    }
  }

  &_active {
    border-color: $main;

    input {
      border-color: $main;
    }
  }

  &__submit-btn {
    background-color: $main;
    cursor: pointer;
    width: 72px;
    position: absolute;
    height: calc(100% + 2px);
    right: -1px;
    border: none;
    top: -1px;
    border-radius: inherit;
    @include center-flex();

    >span {
      display: none;

      @include md {
        display: block;
        color: $white;
        @include font(16px, 24px, 600);
      }
    }

    @include md {
      position: static;
      top: 0;
      left: 0;
      height: 48px;
      width: 100%;
      border-radius: 32px;
      margin-top: 16px;
    }


    svg {
      @include md {
        position: absolute;
        left: 18.5px;
        top: 16.5px;
        @include size(15px, 15px);

        path {
          stroke: #ADADAD;
        }
      }
    }

  }
}