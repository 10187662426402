.cookie {
  padding: 8px 16px 16px 16px;
  width: 416px;
  position: fixed;
  z-index: 100;
  bottom: 24px;
  right: 24px;
  background-color: $white;
  box-shadow: 0px 7px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  display: none;
  visibility: hidden;
  transition: bottom 0.3s ease-in-out;

  @include sm() {
    background: $white;
    @include size(100%, 43px);
    border-radius: 0;
    bottom: 0;
    left: 0;
    box-shadow: none;
    justify-content: space-between;
    padding: 8px 12px;
    z-index: 550;
  }

  &__mobile {
    display: none;

    @include sm() {
      display: flex;
      margin: 0;
      @include font(12, 15.6px, 400);
      align-items: center;
      color: $neutral-100;

      p {
        min-width: 170px;
      }

      a {
        text-decoration: underline;
        color: $neutral-100;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-height: 24px;
    width: 100%;

    @include sm() {
      position: absolute;
      right: 0;
    }
  }

  &__close-btn {
    @include size(24px, 24px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 21px;
    right: 16px;

    @include sm() {
      display: none;
    }
  }

  &__title {
    @include font(16, 20.8px, 600);
    color: $neutral-100;
    margin: 16px 0;

    @include sm() {
      display: none;
    }
  }

  &__btn {
    padding: 8px 16px;
    min-width: 132px;
    min-height: 35px;
    display: inline-block;
    border-radius: 4px;
    @include font(16, 19.36px, 500);
    margin-right: 32px;
    color: $white;
    background-color: $blue-main;
    cursor: pointer;
    text-align: center;
    border: none;

    @include sm() {
      padding: 6px 9px;
      padding-top: 7px;
      padding-bottom: 5px;
      width: fit-content;
      min-height: 27px;
      min-width: 39px;
      @include font(12, 14.52px, 400);
      margin-right: 0;
    }
  }

  &__link {
    display: inline-block;
    @include font(14, 19.6px, 600);
    color: $blue-main;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: solid 1px $blue-main;

    &:hover {
      border-color: transparent;
    }

    @include sm() {
      display: none;
    }
  }

  &_with-navbar {
    @include sm() {
      bottom: 68px;
    }
  }

  &_hidden {
    bottom: -115px;

    @include sm {
      bottom: -55px;
    }
  }
}

.show {
  display: block;
  visibility: visible;

  @include sm() {
    display: flex;
  }
}