.share-btn-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  &__google-news {
    @include size(38px, 30px);
    border: solid 1px $main;
    @include center-flex();
    border-radius: 125px;
    margin-right: 8px;
  }

  &__socials {
    position: absolute;
    top: -21px;
    z-index: 1;
    right: 0;
    transform: translateY(-100%);
    background-color: $white;
    filter: drop-shadow(0px 2px 4px rgba(45, 45, 45, 0.18)) drop-shadow(0px 4px 5px rgba(45, 45, 45, 0.12)) drop-shadow(0px 1px 10px rgba(45, 45, 45, 0.1));
    border-radius: 4px;
    border: solid 1px #CDCDCD;
    padding: 8px;
    height: 40px;
    display: flex;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s linear;
    transition-property: opacity, visibility;

    &::before {
      content: url("/src/images/icons/share-btn-triangle.svg");
      position: absolute;
      right: 12px;
      bottom: 2.8px;
      transform: translateY(100%);
    }

  }

  .share-btn-wrapper__item {
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    border: none;
    display: flex;
    margin-right: 9px;

    img {
      @include size(24px, 24px);
      max-width: fit-content;
    }

    &:nth-child(2) {
      margin-left: 6px;
    }
  }

  button.share-btn-wrapper__item {
    margin-right: 0;
  }

  &_popup {
    .share-btn-wrapper__socials {
      opacity: 1;
      visibility: visible;
    }
  }

  &_white {
    .share-btn {
      color: $white;

      svg path {
        stroke: $white;
      }
    }
  }

}

.share-btn {
  border-radius: 32px;
  height: 30px;
  border: solid 1px $main;
  background-color: transparent;
  display: flex;
  align-items: center;
  padding: 0 10px;
  @include font(12px, 18px, 400);
  cursor: pointer;
  color: $text-color;

  svg {
    margin-right: 8px;
  }

  &_hide-text-mob {
    @include sm {
      width: 38px;
    }

    @include sm {
      >span {
        display: none;
      }

      svg {
        @include sm {
          margin-right: 0;
        }
      }
    }
  }

}