.live-stream-circle {
  @include size(12px, 12px);
  position: relative;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.4);
  top: -1.5px;
  display: inline-block;

  &::before {
    content: "";
    position: absolute;
    @include size(6px);
    top: 3px;
    left: 3px;
    border-radius: 100%;
    background-color: $white;
  }

  &_thumb {
    background: rgba(230, 140, 140, 0.4);
    vertical-align: middle;
    margin-right: 2px;
    bottom: 2px;

    &::before {
      background-color: #FF4F4F;
    }
  }
}
