.hot-line-wrapper {
  min-height: 32px;
  background-color: #F34822;
  position: relative;
  z-index: 999;
  left: 0;
  top: 68px;

  @include md {
    top: 0;
  }

  transition: 0.3s ease;
  transition-property: min-height,
  max-height;

  &_closed {
    max-height: 0px;
    min-height: 0px;

    .hot-line {
      display: none;
    }
  }

}

.hot-line {
  background-color: #F34822;
  width: 100%;
  padding: 6.5px 0;

  @include md {
    top: 0;
    position: relative;
  }

  &_v2 {
    position: relative;
  }

  &_scrolled {
    position: fixed;
    top: 68px;
  }

  > div {
    flex-direction: row;
    justify-content: center;
    position: relative;

    @include md {
      justify-content: flex-start;
    }
  }

  &__link {
    color: $white;
    @include font(14px, 19px, 600);
    display: flex;
    align-items: center;
    padding: 0 24px;

    svg {
      margin-right: 8px;
      min-width: 16px;

      @include md {
        margin-right: 4px;
      }
    }

    > span {
      position: relative;

      &::before {
        content: "🔥";
        padding-right: 10px;
      }
    }

    @include md {
      @include font(12px, 16.5px);
      display: inline-block;
      padding: 0;
      padding-right: 12px
    }
  }

  &__close {
    @include size(16px, 16px);
    border: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 1px;

    @include md {
      position: relative;
      right: -10px;
      top: 2px;
      margin: auto;
    }
  }

}