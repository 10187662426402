.breadcrumbs {
  $b: &;
  padding: 16px 0;
  display: flex;
  align-items: center;
  overflow-x: auto;
  @include hide-scroll();

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    white-space: nowrap;
    @include font(12px, 16px, 600);

    &:first-child {
      margin-left: 0;
    }

    color: $neutral-100;

    &-disabled {
      color: $neutral-70;

      img,
      svg {
        display: none;
      }
    }

    &-arrow {
      padding: 0;
      background-color: transparent;
      border: none;
      margin: 0 8px;
      @include size(20px, 20px);
    }
  }

  &_light {
    #{$b}__link {
      color: $white;

      &-disabled {
        color: $neutral-60;
      }

      &-arrow {
        opacity: 1;
      }
    }

    svg path {
      fill: $white;
    }
  }
}